import { Howl } from 'howler';

import { Maths } from '../_app/cuchillo/utils/Maths';

export default {
  init (opts = {}) {
    this.game = new Game(opts);
    this.game.init();
  },
  destroy () {
    this.game.destroy();
  }
};

class Game {
  defaults = {
    gameSelector: '#game',
    soundSelector: '.__sound',
    playSelector: '.__play-btn',
    randomSelector: '.__random-btn',
    crossfaderSelector: '.__crossfader'
  }

  constructor(opts = {}) {
    this.gameSelector = opts.gameSelector !== undefined ? opts.gameSelector : this.defaults.gameSelector;
    this.soundSelector = opts.soundSelector !== undefined ? opts.soundSelector : this.defaults.soundSelector;
    this.playSelector = opts.playSelector !== undefined ? opts.playSelector : this.defaults.playSelector;
    this.randomSelector = opts.randomSelector !== undefined ? opts.randomSelector : this.defaults.randomSelector;
    this.crossfaderSelector = opts.crossfaderSelector !== undefined ? opts.crossfaderSelector : this.defaults.crossfaderSelector;

    this.activeSounds = new Array(5);
    this.playInterval = null;
    this.loopInterval = null;
    this.playing = false;
    this.game = document.querySelector(this.gameSelector);

    this._toggle = event => this.toggleSound(event);
    this._play = () => this.handlePlay();
    this._random = () => this.handleRandom();
    this._crossfader = event => this.handleCrossfader(event);

    if (this.game) {
      this.sounds = Array.from(this.game.querySelectorAll(this.soundSelector));
      this.playBtn = this.game.querySelector(this.playSelector);
      this.randomBtn = this.game.querySelector(this.randomSelector);
      this.crossfader = this.game.querySelector(this.crossfaderSelector);
      this.playRate = 16 - crossfader.value;
      this.playTime = (1 + this.playRate * 0.59) * 1000;
    }
  }

  init () {
    if (this.game) {
      this.sounds.map(sound => sound.addEventListener('click', this._toggle));
      this.playBtn.addEventListener('click', this._play);
      this.randomBtn.addEventListener('click', this._random);
      this.crossfader.addEventListener('change', this._crossfader);
    }
  }

  destroy () {
    if (this.game) {
      this.stop();
      this.sounds.map(sound => sound.removeEventListener('click', this._toggle));
      this.playBtn.removeEventListener('click', this._play);
      this.randomBtn.removeEventListener('click', this._random);
      this.crossfader.removeEventListener('change', this._crossfader);
    }
  }

  clearColumn (index) {
    if (this.activeSounds[index]) {
      this.activeSounds[index].slow.unload();
      this.activeSounds[index].mid.unload();
      this.activeSounds[index].fast.unload();
      this.activeSounds[index].element.classList.remove('active');
      this.activeSounds[index] = undefined;
    }
  }

  handleCrossfader (event) {
    this.playRate = 16 - event.currentTarget.value;
    this.playTime = (1 + this.playRate * 0.59) * 1000;

    if (this.playing) {
      this.stop();
      this.play();
    }
  }

  toggleSound (event) {
    const element = event.currentTarget;
    const column = parseInt(element.dataset.column);

    if (!element.classList.contains('active')) {
      const trackSlow = element.dataset.trackSlow;
      const trackMid = element.dataset.trackMid;
      const trackFast = element.dataset.trackFast;

      var slow = new Howl({ src: [trackSlow] });
      var mid = new Howl({ src: [trackMid] });
      var fast = new Howl({ src: [trackFast] });

      this.clearColumn(column);
      this.activeSounds[column] = {
        slow,
        mid,
        fast,
        element
      };

      element.classList.add('active');

      //MOSTRAMOS EL SONIDO AL USUARIO
      mid.play();

    } else {
      this.clearColumn(column);
    }
  }

  handlePlay () {
    if (!this.playing) this.play();
    else this.stop();
  }

  handleRandom () {
    this.randomize();
  }

  play () {
    this.loop();

    this.playBtn.classList.add('active');
    this.playing = true;
    this.playInterval = setInterval(() => {
      this.loop();
    }, this.playTime);
  }

  stop () {
    this.playing = false;
    this.playBtn.classList.remove('active');
    this.activeSounds.map(item => {
      if (item) {
        item.slow.stop();
        item.mid.stop();
        item.fast.stop();
      }
    });

    clearInterval(this.playInterval);
    clearInterval(this.loopInterval);
  }

  loop () {
    if (this.activeSounds[0]) {
      this.activeSounds[0].element.classList.add('flash');

      if (this.playRate < 5) this.activeSounds[0].fast.play();
      else if (this.playRate < 11) this.activeSounds[0].mid.play();
      else this.activeSounds[0].slow.play();

      setTimeout(() => { this.activeSounds[0].element.classList.remove('flash') }, 100);
    }

    let index = 1;
    clearInterval(this.loopInterval);
    this.loopInterval = setInterval(() => {
      if (index < this.activeSounds.length) {
        const item = this.activeSounds[index];

        if (item) {
          item.element.classList.add('flash');

          if (this.playRate < 5) item.fast.play();
          else if (this.playRate < 11) item.mid.play();
          else item.slow.play();

          setTimeout(() => { item.element.classList.remove('flash') }, 100);
        }

        index++;
      } else {
        clearInterval(this.loopInterval);
      }
    }, this.playTime / this.activeSounds.length);
  }

  randomize () {
    this.stop();

    for (let i = 0; i < 5; i++) {
      this.clearColumn(i);

      const index = Maths.maxminRandom(-1, 4);
      const randomSound = this.sounds
        .filter(sound => parseInt(sound.dataset.column) === i)[index];

      const trackSlow = randomSound.dataset.trackSlow;
      const trackMid = randomSound.dataset.trackMid;
      const trackFast = randomSound.dataset.trackFast;

      var slow = new Howl({ src: [trackSlow] });
      var mid = new Howl({ src: [trackMid] });
      var fast = new Howl({ src: [trackFast] });

      this.activeSounds[i] = {
        slow,
        mid,
        fast,
        element: randomSound
      }

      randomSound.classList.add('active');
    }

    this.play();
  }
}
