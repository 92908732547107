import { gsap } from "gsap";
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from "../_app/cuchillo/scroll/VScroll_Item";

class ScrollItem__Team extends VScroll_Item {

//==================================================================================================================
//          CONSTRUCTOR
//==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.onShow = () => {

    };

    this.onHide = () => {

    };

    this.onMove = () => {

    }
  }
}

Scroll._registerClass("team", ScrollItem__Team);
