import { Scroll } from '../_app/cuchillo/scroll/Scroll';
// import Scrollbar from '../_app/cuchillo/scroll/Scrollbar';
import Page from '../_app/cuchillo/pages/Page';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import { ControllerWindow } from '../_app/cuchillo/windows/ControllerWindow';
import { Videos } from '../_app/cuchillo/components/Videos';
import Game from '../components/Game';
//import { Acordions } from '../_app/cuchillo/components/Acordions';
import Wrap from '../layout/Wrap';
import Header from '../layout/Header';
import { isMobile } from '../_app/cuchillo/core/Basics';
import { GetBy, C } from "../_app/cuchillo/core/Element";
import Forms from '../_app/cuchillo/forms/FormValidator';
import { WinPopUp } from '../windows/PopUp';
import { Acordions } from '../_app/cuchillo/components/Acordions';
import Keyboard from '../_app/cuchillo/core/Keyboard';

export default class Default extends Page {
  id;
  isWrapAutoRemove = true;
  idTimer;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor() {
    super();
    this.id = "__" + new Date().getTime();

    Acordions.init();
    Videos.init();

    this.setupBlocks();
  }

  _setupComponents() {
    super._setupComponents();
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  setupBlocks() {
    const change = () => {
      C.forEach(".__grad", (e) => {
        e.style.setProperty('--grad', `${Math.round(Math.random())}`);
      });
    }

    change();
    //this.idTimer = setInterval(change, 500)
  }

  //SHOW
  beforeShow() {
    Scroll.init(Scroll.AXIS_Y, { domResize: this.container, smooth: !isMobile, multiplicator: 1 });
    Game.init();
    Forms.init();
    Keyboard.mountPage(this.id);
  }

  show__effect(__call) {
    Wrap.show(() => { this.afterShow() });
  }

  afterShow() {
    super.afterShow();

    Scroll.start();
    Scroll.show();

    Header.show();
    WinPopUp.show();
  }

  //HIDE
  beforeHide() {
    Keyboard.unmountPage(this.id);
    ControllerWindow.hideAll();
    Game.destroy();
  }

  hide__effect() {
    clearInterval(this.idTimer);
    Wrap.hide(() => { this.afterHide(); });
  }

  afterHide() {
    Scroll.hide();
    Scroll.dispose();
    super.afterHide();
  }

  //RESIZE
  resize() {
    super.resize();

    Acordions.resize();
  }

  //LOOP
  loop() {
    if (this._isActive) {
      super.loop();
    }
  }
}

ControllerPage._addPage("default", Default)
