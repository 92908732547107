import Win from '../_app/cuchillo/windows/Window';
import { GetBy } from '../_app/cuchillo/core/Element';
import { gsap, Power3 } from "gsap";

class VideoStories extends Win {
  constructor(__container) {
    super(__container, "video-stories");
    this.directHide();

    this.playing = false;
    this.element = GetBy.id('VideoStories');
    this.videos = [];
    this.index = 0;

    const videos = Array.from(this.element.querySelectorAll('.video-files li'));

    videos.map(video => {
      this.videos.push(video.innerHTML);
    });

    this.video = this.element.querySelector('video');
    this.source = this.video.querySelector('source');
    this.prev = this.element.querySelector('.btn-prev');
    this.next = this.element.querySelector('.btn-next');

    this.prev.addEventListener('click', () => { this.prevSlide(); });
    this.next.addEventListener('click', () => { this.nextSlide(); });
  }

  show__effect (__d = 0) {
    gsap.set(this.container, { x: this.container.offsetWidth, alpha: 1 });
    gsap.to(this.container, {
      x: 0,
      duration: .4,
      ease: Power3.easeOut,
      onComplete: () => {
        this.afterShow();
      }
    });
  }

  afterShow () {
    super.afterShow();
    this.playVideo();
  }

  hide__effect (__d = 0) {
    gsap.to(this.container, {
      x: this.container.offsetWidth,
      duration: .4,
      ease: Power3.easeOut,
      onComplete: () => {
        this.afterHide();
      }
    });
  }

  afterHide () {
    super.afterHide();
    this.stopVideo();
  }

  directHide () {
    gsap.set(this.container, { x: this.container.offsetWidth, alpha: 1 });
    super.directHide();
  }

  resize () {
    super.resize();
  }

  playVideo () {
    this.playing = true;
    this.video.play();
  }

  stopVideo () {
    if (this.playing) {
      this.playing = false;
      this.video.currentTime = 0;
      this.video.pause();
    }
  }

  nextSlide () {
    this.index += 1;

    this.stopVideo();
    this.source.setAttribute('src', this.videos[this.index % this.videos.length]);
    this.video.load();
    this.playVideo();
  }

  prevSlide () {
    this.index -= 1;

    this.stopVideo();
    this.source.setAttribute('src', this.videos[this.index % this.videos.length]);
    this.video.load();
    this.playVideo();
  }
}

export const WinVideoStories = new VideoStories(GetBy.id("VideoStories"));
