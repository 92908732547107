import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { Scroll } from './_app/cuchillo/scroll/Scroll';
import { VInsider } from './_app/cuchillo/scroll/insiders/VInsider';
import { VSliderImage } from './scroll/VSliderImage';

import Keyboard from './_app/cuchillo/core/Keyboard';
import { Basics, isDebug, isMobile, isTouch } from './_app/cuchillo/core/Basics';
import { Accessibility } from './_app/cuchillo/core/Accessibility';
import { Statics } from './_app/cuchillo/utils/Statics';

import LoaderController from './_app/cuchillo/loaders/LoaderController';
import PagesLoader from './_app/cuchillo/loaders/PagesLoader';
import MediaLoader from './_app/cuchillo/loaders/MediaLoader';
import { ControllerPage } from './_app/cuchillo/pages/ControllerPage';
import { Metrics } from './_app/cuchillo/core/Metrics';
import Default from './pages/Default';
import Legal from './pages/Legal';
import EventDispatcher from './_app/cuchillo/core/EventDispatcher';
import Page from './_app/cuchillo/pages/Page';
import { Interaction, MrInteraction } from './_app/cuchillo/core/Interaction';
import { gsap } from "gsap";
import { ControllerWindow } from './_app/cuchillo/windows/ControllerWindow';
import Win from './_app/cuchillo/windows/Window';

import Wrap from './layout/Wrap';
import InterfaceCanvas from './_app/cuchillo/layout/InterfaceCanvas';
import Cursor from './_app/cuchillo/cursor/Cursor';
import Loading from './layout/Loading';
import BG from './_app/cuchillo/layout/Background';
import { Colors } from './Colors';
import Cookies from './windows/Cookies';
import Header from './layout/Header';

import { ScrollItem__Team } from './scroll/ScrollItem__Team';
import { ScrollItem__Line } from './scroll/ScrollItem__Line';
import { ScrollItem__Thumb } from './scroll/ScrollItem__Thumb';
import { ScrollItem__Sticker } from './scroll/ScrollItem__Sticker';
import { ScrollItem__KPI } from './scroll/ScrollItem__KPI';
import { ScrollItem__GalleryFull } from './scroll/ScrollItem__GalleryFull';
import { ScrollItem__SliderDefault } from './scroll/ScrollItem__SliderDefault';

import { WinVideoStories } from './windows/VideoStories';
import { WinPopUp } from './windows/PopUp';
import { WinSidemenu } from './windows/Sidemenu';
import { WinModalGallery } from "./windows/ModalGallery";

export default class Main {
  static scrollbar;
  static stats;

  static init() {
    Basics.id = "boilerplate_eleventy_cuchillo_v002"; // ID para cookies

    Header.init();
    Cursor.init(
      document.body,
      {
        color: "#FF00FF",
        fontStyle: {
          size: 16,
          unit: "px",
          fontFamily: "monospace",
          color: "#000000",
          textAlign: "center"
        }
      },
      { size: 0, stroke: 0, alpha: 0, strokeBG: 0, strokeAlpha: 0 }, //ARROW
      { size: 0, stroke: 0, alpha: 0, strokeBG: 0, strokeAlpha: 0 }, //FOLLOWER
      true
    );
    Cursor.start();

    Metrics.init(() => Main.resize()); // Tamaños y resize
    Keyboard.enable(); // ESC para cerrar ventana
    Accessibility.init(); // Utils accesibilidad
    Statics.init(); // Si estamos en debug pinta un FPS counter
    Interaction.init({ ajax: true }); // Posiciones del cursor (Movimiento, click...), Acciones links, drag...
    ControllerWindow.init(); // Control ventanas

    BG.init(Colors); // Control de paletas y color de fondo de pantallas. Automatico si añadimos un data-palette='loquesea' en el div con data-page
    InterfaceCanvas.init(); // Canvas de interface, se usa con Cursor
    Cookies.init(); // Checkea y saca el aviso de cookies

    LoaderController.add(new PagesLoader()); // Carga/Precarga de paginas HTML
    LoaderController.add(new MediaLoader()); // Carga/Precarga de imgs
    LoaderController.onComplete = () => Main.setup();
    LoaderController.init();

    // LOOP
    if (isDebug) {
      gsap.ticker.add(() => { Main.loopDebug(); });
    } else {
      gsap.ticker.add(() => { Main.loop(); });
    }
  }

  static setup() {
    this.setupEvents();
    // INIT PAGE
    ControllerPage.init(Wrap.mainholder);

    // WinPopUp.show();
  }

  static setupEvents() {
    EventDispatcher.addEventListener(Page.ON_SHOW, () => {
      Cursor.start();
      Loading.stop();
    });
    EventDispatcher.addEventListener(Page.ON_HIDE, () => {
      Cursor.hide();
    });
    EventDispatcher.addEventListener(Page.ON_HIDE_END, () => {
      Loading.start();
    });

    EventDispatcher.addEventListener(Win.ON_HIDE, () => { Scroll.setEnabled(true); });
    EventDispatcher.addEventListener(Win.ON_SHOW, () => { Scroll.setEnabled(false); });
  }

  static resize() {
    Header.resize();
    InterfaceCanvas.resize();
    ControllerPage.resize();
    Scroll.resize();
  }

  static loop() {
    // ControllerPage.loop();
    Header.loop();
    InterfaceCanvas.loop();
    Cursor.loop();

    if (Scroll.isScrolling) Scroll.loop();
  }

  static loopDebug() {
    Statics.begin();
    this.loop();
    Statics.end();
  }
}

if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
  Main.init();
} else {
  document.addEventListener('DOMContentLoaded', Main.init);
}
