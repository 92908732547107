import gsap, { Power4 } from "gsap";

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from "../_app/cuchillo/scroll/VScroll_Item";

class ScrollItem__Sticker extends VScroll_Item {
  selector = "[data-sticker]";

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.targets = Array.from(this.item.querySelectorAll(this.selector));

    this.onShow = () => {
      this.targets.map((target, i) => {
        gsap.to(target, {
          scaleX: 1,
          scaleY: 1,
          opacity: 1,
          duration: 0.4,
          delay: 0.7 + (0.1 * i),
          ease: Power4.easeOut
        });
      });
    }
  }
}

Scroll._registerClass('sticker', ScrollItem__Sticker);
