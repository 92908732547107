import gsap, { Power2 } from "gsap";

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from "../_app/cuchillo/scroll/VScroll_Item";

class ScrollItem__Line extends VScroll_Item {
  selector = "[data-line]";

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.targets = Array.from(this.item.querySelectorAll(this.selector));
    this.targets.map(target => target.style.transformOrigin = 'left center');

    this.onMove = () => {
      this.targets.map(target => {
        const factor = parseFloat(target.dataset.line);
        const initial = parseFloat(target.dataset.lineInitial);
        const scaleX = initial + (factor * this.progress);

        gsap.to(target, {
          scaleX,
          duration: 0.1,
          ease: Power2.easeOut
        });
      });
    };
  }
}

Scroll._registerClass('line', ScrollItem__Line);
