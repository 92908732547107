import gsap, { Power2, Linear } from 'gsap';

import Win from '../_app/cuchillo/windows/Window';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Basics } from '../_app/cuchillo/core/Basics';
import { Cookie } from '../_app/cuchillo/utils/Cookie';

class PopUp extends Win {
  _front;
  _back;
  _overlay;
  _enabled;
  _popupId;
  _btn;
  _close;

  constructor(__container) {
    super(__container, "pop-up");

    this._front = GetBy.class('__front', __container)[0];
    this._back = GetBy.class('__back', __container)[0];
    this._overlay = GetBy.class('__overlay', __container)[0];
    this._popupId = __container.dataset.id;
    this._btn = GetBy.selector('a', __container)[0];
    this._close = GetBy.selector('button', __container)[0];
      
    this._btn.addEventListener(Basics.clickEvent, () => { this.hide(); });

    this.directHide();
  }

  show__effect (__d = 0) {
    gsap.set(this._overlay, { alpha: 0 });
    gsap.set(this._close, { alpha: 0, rotateZ: '-540deg' });
    gsap.set(this._front, { y: '100%' });
    gsap.set(this._back, { y: '100%' });

    gsap.to(this._overlay, {
      alpha: .5,
      duration: .3,
      delay: .2,
      ease: Linear.easeNone
    });
    gsap.to(this._front, {
      y: 0,
      duration: 1.2,
      delay: .2,
      ease: 'elastic.out(.6, 0.4)'
    });
    gsap.to(this._back, {
      y: 0,
      duration: 1.2,
      delay: .4,
      ease: 'elastic.out(.6, 0.4)',
      onComplete: () => {
        this.afterShow();
      }
    });

    gsap.to(this._close, {
      alpha: 1,
      rotateZ: 0,
      duration: 1,
      delay: .6,
      ease: Power2.easeOut
    });
  }

  hide__effect (__d = 0) {
    gsap.to(this._overlay, {
      alpha: 0,
      duration: .2,
      delay: .2,
      ease: Linear.easeNone
    });

    gsap.to(this._close, {
      alpha: 0,
      rotateZ: '-360deg',
      duration: .6,
      delay: .2,
      ease: Power2.easeOut
    });

    gsap.to(this._front, {
      y: '100%',
      duration: .6,
      ease: 'elastic.in(.8, 1)'
    });
    gsap.to(this._back, {
      y: '100%',
      duration: .6,
      delay: .1,
      ease: 'elastic.in(.8, 1)',
      onComplete: () => {
        this.afterHide();
      }
    });
  }

  show (__d = 0) {
    if (!Cookie.get(this._popupId)) super.show(__d);
  }

  hide (__d = 0) {
    super.hide(__d);
    Cookie.set(this._popupId);
  }
}

let popup = {
  show: () => {},
  hide: () => {}
};

if (GetBy.id("pop-up")) popup = new PopUp(GetBy.id("pop-up"))

export const WinPopUp = popup;
