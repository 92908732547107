import Win from '../_app/cuchillo/windows/Window';
import { GetBy } from '../_app/cuchillo/core/Element';
import { gsap, Power3 } from "gsap";

class Sidemenu extends Win {
  constructor(__container) {
    super(__container, "sidemenu");

    this.directHide();

    this._navItems = Array.from(GetBy.selector('.sidemenu__nav .link'));
    this._contact = Array.from(GetBy.selector('.sidemenu__contact-links'));
  }

  show__effect (__d = 0) {
    gsap.set(this.container, { alpha: 0 });
    gsap.set(this._navItems, {
      alpha: 0,
      rotateZ: '5deg',
      rotateX: '5deg'
    });
    gsap.set(this._contact, { alpha: 0 });

    gsap.to(this.container, { alpha: 1, duration: .3, ease: Power3.easeIn });

    this._navItems.map((item, i) => {
      item.style.transformOrigin = 'left center';

      gsap.to(item, {
        alpha: 1,
        rotateZ: 0,
        rotateX: 0,
        duration: .6,
        delay: .4 + (.05 * i),
        ease: Power3.easeOut
      });
    });

    gsap.to(this._contact, {
      alpha: 1,
      duration: .4,
      delay: .6,
      ease: Power3.easeOut,
      onComplete: () => { this.afterShow(); }
    });
  }

  hide__effect (__d = 0) {
    gsap.to(this._navItems, {
      alpha: 0,
      duration: .3,
      ease: Power3.easeOut,
    });
    gsap.to(this._contact, {
      alpha: 0,
      duration: .3,
      ease: Power3.easeOut,
    });

    gsap.to(this.container, { alpha: 0, duration: .2, delay: .3, ease: Power3.easeOut, onComplete: () => { this.afterHide(); } });
  }

  directHide () {
    gsap.set(this.container, { alpha: 0 });
    super.directHide();
  }

  resize () {
    super.resize();
  }
}

export const WinSidemenu = new Sidemenu(GetBy.id("Sidemenu"));
