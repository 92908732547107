export const Colors = {
  "primary": {
    hex: 0xDDDDDD,
    css: "#DDD",
    str: "palette-primary"
  },
  "secondary": {
    hex: 0x000000,
    css: "#000000",
    str: "palette-black"
  }
}
