import gsap, { Power2 } from "gsap";

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from "../_app/cuchillo/scroll/VScroll_Item";

class ScrollItem__KPI extends VScroll_Item {
  selector = "[data-counter]";
  selector2 = "[data-line]";

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.counters = Array.from(this.item.querySelectorAll(this.selector));
    this.lines = Array.from(this.item.querySelectorAll(this.selector2));
    this.lines.map(target => target.style.transformOrigin = 'left center');

    this.onShow = () => {
      this.counters.map(target => {
        const counter = { val: 0 };
        const number = parseFloat(target.dataset.counter.replace(',', '.'));
        const hasDecimals = number % 1 !== 0;

        gsap.to(counter, {
          val: number,
          duration: 1.5,
          delay: 0.3,
          onUpdate: () => {
            if (hasDecimals) {
              target.innerHTML = counter.val.toFixed(2).replace('.', ',');
            } else {
              target.innerHTML = counter.val.toFixed(0);
            }
          },
          ease: Power2.easeOut
        });
      });
    }

    this.onMove = () => {
      this.lines.map(target => {
        const factor = parseFloat(target.dataset.line);
        const initial = parseFloat(target.dataset.lineInitial);
        const scaleX = initial + (factor * this.progress);

        gsap.to(target, {
          scaleX,
          duration: 0.1,
          ease: Power2.easeOut
        });
      });
    };
  }
}

Scroll._registerClass('kpi', ScrollItem__KPI);
