import { GetBy, C } from '../core/Element';
import { Basics } from '../core/Basics';

export const Acordions = {

  _acordions: [],

  init() {
    this._acordions = [];
    C.forEach('[data-acordion]', (e) => { this._acordions.push(new Acordion(e)); })
  },

  toggle: function (__btn) {
    const ACORDION = __btn.parentNode;
    ACORDION.setAttribute('aria-expanded', ACORDION.getAttribute('aria-expanded') === 'false');
  },

  resize() {
    for (let i = 0; i < this._acordions.length; i++) {
      this._acordions[i].resize();
    }
  }
};

class Acordion {
  container;
  group;
  toggle;

  constructor(__container, __id) {
    this.container = __container;
    this.toggle = GetBy.selector('[data-acordion-toggle]', __container)[0];
    this.group = GetBy.selector('[data-acordion-group]', __container)[0];
    const isExpanded = this.container.getAttribute('aria-expanded') || 'false';
    this.container.setAttribute('aria-expanded', isExpanded);

    if (isExpanded == 'false') {
      this.toggle.addEventListener(Basics.clickEvent, (e) => {
        e.preventDefault();
        this.toggleState();
      });
    }

    this.resize();
  }

  toggleState() {
    this.container.setAttribute('aria-expanded', this.container.getAttribute('aria-expanded') === 'false');
  }

  resize() {
    this.container.style.setProperty('--height-close', this.toggle.offsetHeight + 'px');
    this.container.style.setProperty('--height-open', this.group.offsetHeight + 'px');
  }
}
