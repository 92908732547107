import gsap, { Power2 } from "gsap";

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from "../_app/cuchillo/scroll/VScroll_Item";

class ScrollItem__Thumb extends VScroll_Item {
  selector = "[data-thumb]";

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.target = this.item.querySelector(this.selector);

    this.onMove = () => {
      gsap.to(this.target, {
        height: `${(this.progress * 2) * 100}%`,
        duration: 0.1,
        ease: Power2.easeOut
      });
    }
  }
}

Scroll._registerClass('thumb', ScrollItem__Thumb);
